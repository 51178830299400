<template>
	<section class="not-found">
		<icon-not-found class="not-found__image" />
		<section class="not-found__message">
			<h2 class="not-found__heading">Что-то пошло не так</h2>
			<p class="not-found__text">Попробуйте перезагрузить страницу или вернуться на главную</p>
			<div class="not-found__buttons">
				<a href="javascript:history.back()" class="link__btn btn__blue">Назад</a>
				<a href="/" class="link__btn">На главную</a>
			</div>
		</section>
	</section>
</template>

<script>
import IconNotFound from '@/assets/svg/IconNotFound.vue';

export default {
	name: 'NotFound',
	components: { IconNotFound },
}
</script>

<style scoped>
	.not-found {
		max-width: 687px;
		padding: 21px 20px 0;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.not-found__image {
		margin: 0 auto;
		width: 100%;
		max-width: 564px;
	}
	.not-found__buttons {
		display: flex;
		justify-content: space-between;
		gap: 25px;
		flex-wrap: wrap;
		margin-top: 50px;
	}

	.not-found__buttons a {
		flex: 1 0;
	}

	.not-found__message {
		text-align: center;
		margin-top: 20px;
		width: 100%;
	}

	.not-found__heading {
		font-size: var(--subheading-fs);
		font-weight: var(--subheading-fw);
	}

	.not-found__text {
		margin: 5px 0 0;
		font-size: var(--regular-fs);
		font-weight: var(--regular-fw);
	}
</style>
