<template>
  <main class="vcc-main">
	<h1 class="visually-hidden">Страница не найдена. HTTP 404 "page not found"</h1>
	<not-found />
  </main>
</template>

<script>

import NotFound from '@/components/common/NotFound.vue';
export default {
	name: 'NotFoundPage',
	components: { NotFound },
}

</script>
